<template>
  <div>
    <app-header></app-header>

    <div class="login">
      <div class="login-logo text-center">
        <img src="../../assets/images/logo.png" alt="" class="" />
        <p class="text-center">{{ $t("New registration in Here Home") }}</p>
      </div>
      <div class="text-center">
        <form method="post" @submit.prevent="save()">
          <div class="page">
            <div class="field">
              <label for="name">{{ $t("name") }}</label>
              <InputText
                id="name"
                type="text"
                v-model="body.userName"
                :class="{ 'is-invalid': isInvalid && !body.userName }"
                :placeholder="$t('Please enter the name')"
              />
            </div>
            <div class="field">
              <label for="phone">{{ $t("phone") }}</label>

              <vue-tel-input
                v-model="body.phone"
                v-bind="{
                  mode: 'international',
                }"
                style="
                  direction: initial;
                  border-radius: 11px;
                  box-shadow: none;
                  border: 1px solid #ced4da;
                  padding: 6px;
                "
                class="p-inputtext p-component"
                :placeholder="$t('Please enter the mobile number')"
              >
              </vue-tel-input>
            </div>
            <div class="field">
              <label for="email">{{ $t("E-mail") }}</label>
              <InputText
                id="email"
                type="text"
                v-model="body.email"
                :class="{ 'is-invalid': isInvalid && !body.email }"
                :placeholder="$t('Please enter the Email address')"
              />
            </div>
            <div class="field">
              <label for="password">{{ $t("password") }}</label>
              <Password
                toggleMask
                id="password"
                v-model="body.password"
                :class="{ 'is-invalid': isInvalid && !body.password }"
                :placeholder="$t('Please enter in the password')"
              />
            </div>

            <div class="field">
              <label for="confirmPassword">{{ $t("confirm password") }}</label>
              <Password
                toggleMask
                id="confirmPassword"
                v-model="body.confirmPassword"
                :class="{ 'is-invalid': isInvalid && !body.confirmPassword }"
                :placeholder="$t('Please enter in the confirm Password')"
              />
            </div>

            <div class="field-checkbox">
              <Checkbox id="agree" v-model="body.agree" :binary="true" />
              <label for="agree"
                >{{ $t("Agree to") }}
                <span @click="gotoTermsAndConditions()"> {{$t('the terms and conditions')}} </span>
              </label>
            </div>

            <button
              class="btn btn-primary btn-login"
              type="submit"
              :disabled="disabledAdd"
            >
              {{ $t("new registration") }}
            </button>
          </div>
        </form>

        <div class="linked">
          {{ $t("you have an account?") }}
          <router-link to="/login">{{ $t("login") }}</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppHeader from "../../layouts/header.vue";
export default {
  components: {
    AppHeader,
  },
  data() {
    return {
      body: {
        userName: null,
        email: null,
        password: null,
        phone: null,
        countryCode: null,
      },
      isInvalid: false,
      disabledAdd: false,
    };
  },

  methods: {
    gotoTermsAndConditions(){
let route = this.$router.resolve({ path: "/page/termsAndConditions" });
      window.open(route.href);
    },
    save() {
      if (
        this.body.userName &&
        this.body.password &&
        this.body.phone &&
        this.body.email &&
        this.body.password == this.body.confirmPassword &&
        this.body.agree == true
      ) {
        this.disabledAdd = true;

        this.$http.post("auth/signup", this.body).then(
          (res) => {
            this.disabledAdd = false;
            // this.$router.push("/login");
            this.$router.push("/activate?phone=" + this.body.phone);

            this.$toast.add({
              severity: "success",
              summary: this.$t("Done Successfully"),
              detail: this.$t("Register Successfully"),
              life: 3000,
            });
          },
          (err) => {
            this.disabledAdd = false;

            this.$toast.add({
              severity: "error",
              summary: this.$t("Error"),
              detail: err.response.data.message,
              life: 3000,
            });
          }
        );
      } else {
        this.isInvalid = true;
        this.$toast.add({
          severity: "error",
          summary: this.$t("Error"),
          detail: this.$t("Please add the required fields"),
          life: 3000,
        });
      }
    },
  },
  async created() {},
};
</script>
<style></style>
